<template>
  <ShotDialog
    v-model:edit-item="contextSelected"
    v-model:sequences="sequences"
    v-model:show-dialog="showDialog"/>
  <DataTable
    v-model:context-menu-selection="contextSelected"
    v-model:editing-rows="editingRows"
    v-model:selection="selections"
    :loading="loading"
    :rowClass="rowClass"
    scroll-height="flex"
    :value="filteredItems"
    class="p-datatable-sm"
    column-resize-mode="fit"
    data-key="id"
    edit-mode="cell"
    removable-sort
    reorderable-columns
    resizable-columns
    responsive-layout="scroll"
    scrollable
    selection-mode="single"
    show-gridlines
    sort-mode="single"
    state-key="Shots-grid"
    state-storage="local"
    striped-rows
    @row-contextmenu="rowContextMenu"
    @cell-edit-complete="update">
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div>
          <h3 class="p-text-secondary">Shots</h3>
        </div>
        <div>
          Filter:
          <Dropdown
            v-model="episodeFilter"
            :options="episodes"
            class="dropdown-filter"
            filter
            option-label="name"
            option-value="id"
            placeholder="Episode"
            show-clear/>
          <Dropdown
            v-model="sequenceFilter"
            :options="filteredSequences"
            class="dropdown-filter"
            filter
            option-label="name"
            option-value="id"
            placeholder="Sequence"
            show-clear/>
        </div>
      </div>
    </template>
    <template #footer>
      <Toolbar>
        <template #start>
          <Button label="Add shot" icon="pi pi-plus" class="mx-1" @click="addRow()"/>
        </template>
        <template #end>
          In total there are {{ filteredItems?.length || 0 }} shots.
        </template>
      </Toolbar>
    </template>
    <template #loading>
      Loading data. Please wait.
    </template>
    <template #empty>
      {{ error || "No Shots" }}
    </template>
    <Column
      header="Sequence"
      field="sequence.name"
      :sortable="true"
      class="table-row-min"
      style="width: 20%">
      <!--
      <template #editor="{ data, field }">
        <AutoComplete
            field="name"
            v-model="data[field]"
            class="input-text-autocomplete"
            :suggestions="sequenceSuggestions"
            :dropdown="true"
            @complete="searchSequence($event)"
        />
      </template>
      <template #body="props">
        {{ props.data.sequence?.name }}
      </template>
      -->
    </Column>
    <Column
      header="Name"
      field="name"
      :sortable="true"
      class="table-row-min"
      style="width: 20%">
      <!--
      <template #editor="{ data, field }">
        <InputText v-model="data[field]" autofocus class="input-text"/>
      </template>
      -->
    </Column>
    <Column
      header="Duration"
      field="frames"
      :sortable="true"
      class="table-row-min"
      style="width: 20%">
      <!--
      <template #editor="{ data, field }">
        <InputText v-model="data[field]" autofocus class="input-text"/>
      </template>
      -->
      <template #body="{data, field}">
        {{ this.formatFrames(data[field]) }}
      </template>
      <template #footer>
        {{ shotsTime }}
      </template>
    </Column>
    <Column
      class="table-row-min"
      field="url"
      header="Dropbox"
      style="width: 30%">
      <template #body="{ data, field }">
        <span
          v-linkified
          v-html="data[field]"/>
      </template>
      <!--
      <template #editor="{ data, field }">
        <InputText v-model="data[field]" autofocus class="input-text"/>
      </template>
      -->
    </Column>
    <Column
      class="table-row-min"
      field="urlVideo"
      header="Video"
      style="width: 20%">
      <template #body="{ data, field }">
        <span
          v-linkified
          v-html="data[field]"/>
      </template>
      <!--
      <template #editor="{ data, field }">
        <InputText v-model="data[field]" autofocus class="input-text"/>
      </template>
      -->
    </Column>
  </DataTable>
  <ContextMenu :model="menuModel" ref="cm"/>
</template>

<script type="module">
import SequencesService from "@/services/entity/SequencesService";
import GridService from "@/lib/GridService";
import ShotDialog from "@/views/editors/grid/dialog/ShotDialog";
import ShotsService from "@/services/entity/ShotsService";
import EpisodesService from "@/services/entity/EpisodesService";
import ComponentStorage from "@/services/ComponentStorage";

export default {
  name: "ShotsGrid",
  components: {ShotDialog},
  data() {
    return {
      shotsTime: 0,
      filterStorage: null,
      gridService: null,
      service: null,
      episodeService: null,
      sequencesService: null,
      changeState: {},

      episodeFilter: null,
      sequenceFilter: null,

      showDialog: false,
      episodeSuggestions: null,
      sequenceSuggestions: null,
      episodes: [],
      sequences: [],
      error: null,
      items: [],
      editingRows: [],
      selections: [],
      contextSelected: null,
      loading: true,
      menuModel: [
        {
          label: "Edit",
          icon: "pi pi-fw pi-pencil",
          command: () => this.openEditor(this.contextSelected)
        },
        {
          label: "Copy",
          icon: "pi pi-fw pi-copy",
          command: () => this.addRow(this.contextSelected)
        },
        {
          label: "Delete",
          icon: "pi pi-fw pi-times",
          command: () => this.deleteConfirm(this.contextSelected)
        }
      ]
    }
  },
  created() {
    this.filterStorage = new ComponentStorage(this.$options.name)
    this.service = new ShotsService();
    this.episodeService = new EpisodesService();
    this.sequencesService = new SequencesService();
    this.gridService = new GridService();
  },
  mounted() {
    this.get()
    this.episodeFilter = this.filterStorage.load("episodeFilter")
    this.sequenceFilter = this.filterStorage.load("sequenceFilter")
  },
  computed: {
    filteredSequences() {
      if (this.episodeFilter) {
        return this.sequences?.filter(it => it.episode?.id === this.episodeFilter)
      } else {
        return this.sequences
      }
    },
    filteredItems() {
      if (this.sequenceFilter) {
        return this.items?.filter(it => this.sequenceFilter === it.sequence?.id || it.id == null)
      } else if (this.episodeFilter) {
        return this.items?.filter(it => this.episodeFilter === it.sequence?.episode?.id || it.id == null)
      } else {
        return this.items
      }
    },
  },
  watch: {
    episodeFilter(value, oldValue) {
      if (value) {
        if (oldValue) {
          this.sequenceFilter = null
        }
        this.filterStorage.store("episodeFilter", value)
      } else {
        this.filterStorage.clear("episodeFilter")
      }
    },
    sequenceFilter(value) {
      if (value) {
        this.filterStorage.store("sequenceFilter", value)
      } else {
        this.filterStorage.clear("sequenceFilter")
      }
    },
    filteredItems: {
      deep: true,
      handler(items) {
        let sum = items?.map(
          item => item.frames
        ).reduce(
          (acc, val) => acc + (val | 0), 0
        )
        this.shotsTime = this.formatFrames(sum)
      }
    }
  },
  methods: {
    get() {
      this.service.list().then(data => {
        if (!data.error) {
          this.items = data
          this.loading = false
        } else {
          this.error = data.message
        }
      }).catch(e => {
        this.error = e
        this.loading = false
      })
      this.episodeService.list().then(data => {
        this.episodes = data
      })
      this.sequencesService.list().then(data => {
        this.sequences = data
      })
    },
    addRow() {
      this.contextSelected = this.gridService.addRow(this.items)
      this.openEditor()
    },
    update(event) {
      this.gridService.updateRowEvent(
        event,
        this.service,
        this.changeState,
        true
      )
    },
    delete(item) {
      this.gridService.delete(
        this.items,
        item,
        this.service,
        this.changeState
      )
    },
    deleteConfirm(item) {
      this.$confirm.require({
        group: "confirm",
        header: "Delete Confirmation",
        message: "Do you want to delete this record?",
        icon: "pi pi-times",
        acceptClass: "p-button-danger",
        accept: () => this.delete(item),
      });
    },
    rowContextMenu(event) {
      this.gridService.rowContextMenu(
        event,
        this.$refs.cm,
        this.contextSelected
      )
    },
    searchEpisode(event) {
      this.episodeSuggestions = this.episodes.filter(
        it => it.name.toLowerCase().includes(event.query.toLowerCase())
      )
    },
    searchSequence(event) {
      this.sequenceSuggestions = this.sequences.filter(it => {
        if (this.episodeFilter) {
          if (it.episode?.id !== this.episodeFilter) {
            return false
          }
        }
        return it.name.toLowerCase().includes(event.query.toLowerCase())
      })
    },
    rowClass(item) {
      return this.gridService.rowClass(
        item,
        this.changeState
      )
    },
    openEditor() {
      this.showDialog = true
    },
    formatFrames(frames) {
      if (frames == null || frames === 0) {
        return "00:00 [0]"
      }
      try {
        return new Date(frames / 25 * 1000)
          .toISOString()
          .substring(frames >= 90000 ? 11 : 14, 19) + " [" + frames + "]"
      } catch (e) {
        return "00:00 [0]"
      }
    }
  }
}
</script>
