import axios from "axios";

export default class UserService {
    _url = "/api/user/"

    profile() {
        return axios.get(this._url + "profile")
            .then(result => result.data);
    }

    tasks() {
        return axios.get(this._url + "tasks")
            .then(result => result.data);
    }
}