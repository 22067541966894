<template>
  <UserDialog
    v-model:edit-item="contextSelected"
    v-model:show-dialog="showDialog"/>
  <DataTable
    v-model:context-menu-selection="contextSelected"
    v-model:editing-rows="editingRows"
    v-model:selection="selections"
    :loading="loading"
    :rowClass="rowClass"
    :value="items"
    class="p-datatable-sm"
    column-resize-mode="fit"
    data-key="id"
    removable-sort
    reorderable-columns
    resizable-columns
    responsive-layout="scroll"
    scroll-height="flex"
    scrollable
    selection-mode="single"
    show-gridlines
    sort-mode="single"
    state-key="Users-grid"
    state-storage="local"
    striped-rows
    @row-dblclick="openEditor($event.data)"
    @row-contextmenu="rowContextMenu"
    @cell-edit-complete="update">
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <h3 class="p-text-secondary">Users</h3>
      </div>
    </template>
    <template #footer>
      <Toolbar>
        <template #start>
          <Button
            label="Add user"
            icon="pi pi-plus"
            class="mx-1"
            @click="addRow()"/>
        </template>
        <template #end>
          In total there are {{ items?.length || 0 }} users.
        </template>
      </Toolbar>
    </template>
    <template #loading>
      Loading data. Please wait.
    </template>
    <template #empty>
      {{ error || "No Users." }}
    </template>
    <Column
      header="Name"
      field="name"
      :sortable="true"
      class="table-row-min">
      <template #editor="{ data, field }">
        <InputText
          v-model="data[field]"
          class="input-text"/>
      </template>
    </Column>
    <Column
      header="Email"
      field="email"
      :sortable="true"
      class="table-row-min">
      <template #editor="{ data, field }">
        <InputText
          v-model="data[field]"
          class="input-text"/>
      </template>
    </Column>
    <!--
    <Column
        header="Department"
        field="department">
    </Column>
    -->
    <Column
      header="Roles"
      field="workerRoles">
      <template #body="{ data, field}">
        <Chip
          v-for="item in data[field]"
          :key="item.id">
          {{ item.name }}
        </Chip>
      </template>
    </Column>
    <Column
      header="Suspended"
      field="disabled">
      <template #body="{ data, field}">
        <Checkbox
          binary
          disabled
          v-model="data[field]"/>
      </template>
    </Column>
  </DataTable>
  <ContextMenu :model="menuModel" ref="cm"/>
</template>
<script>

import GridService from "@/lib/GridService";
import UsersService from "@/services/entity/UsersService";
import UserDialog from "@/views/admin/auth/dialog/UserDialog";

export default {
  name: "UsersGrid",
  components: {UserDialog},
  data() {
    return {
      changeState: {},
      showDialog: false,
      error: null,
      items: [],
      editingRows: [],
      selections: [],
      contextSelected: null,
      loading: true,
      menuModel: [
        {
          label: "Edit",
          icon: "pi pi-fw pi-pencil",
          command: () => this.openEditor(this.contextSelected)
        }
      ]
    }
  },

  created() {
    this.service = new UsersService();
    this.gridService = new GridService();
  },

  mounted() {
    this.get()
  },

  methods: {
    get() {
      this.service.list().then(data => {
        if (!data.error) {
          this.items = data
          this.loading = false
        } else {
          this.error = data.message
        }
      }).catch(e => {
        this.error = e
        this.loading = false
      });
    },
    addRow() {
      this.contextSelected = this.gridService.addRow(this.items)
      this.openEditor()
    },
    update(event) {
      this.gridService.updateRowEvent(
        event,
        this.service,
        this.changeState,
        true
      )
    },
    delete(item) {
      this.gridService.delete(
        this.items,
        item,
        this.service,
        this.changeState
      )
    },
    deleteConfirm(item) {
      this.$confirm.require({
        group: "confirm",
        header: "Delete Confirmation",
        message: "Do you want to delete this record?",
        icon: "pi pi-times",
        acceptClass: "p-button-danger",
        accept: () => this.delete(item),
      });
    },
    rowContextMenu(event) {
      this.gridService.rowContextMenu(
        event,
        this.$refs.cm,
        this.contextSelected
      )
    },
    rowClass(item) {
      return this.gridService.rowClass(
        item,
        this.changeState
      )
    },
    openEditor(item) {
      if (item) {
        this.contextSelected = item
      }
      this.showDialog = true
    },
  },
}
</script>
