export default class GridService {
    updateRowEvent(event, service, state, disableFilter) {
      let {data, newData} = event
      const {value, newValue, field} = event;
      const hasChanges = JSON.stringify(data) !== JSON.stringify(newData) || value !== newValue
      if (hasChanges || data.id === null) {
        state[data.id] = {
          changed: true,
        }
      } else if (!disableFilter) {
        return
      }
      if (state[data.id] == null) {
        state[data.id] = {}
      }
      if (state[data.id].saved === false) {
            state[data.id].changed = hasChanges
            return
        }
        if (newValue != null) {
            data[field] = newValue
        } else {
            data = newData
        }
        if (data.id > 0) {
            state[data.id].saved = false
            service.update(data).then(result => {
                data[field] = result[field]
                state[data.id].saved = true
                state[data.id].changed = false
            }).catch(() => {
                state[data.id].saved = false
                newData = data
            })
        } else {
            if (this.validate(data)) {
                state[data.id] = {saved: false};
                service.create(data).then(result => {
                    state[data.id].saved = true
                    state[data.id].changed = false
                    Object.keys(result).forEach(key => {
                        data[key] = result[key]
                    })
                }).catch(() => {
                    state[data.id].saved = false
                })
            } else {
                state[data.id].changed = true
            }
        }
    }

    rowClass(data, state) {
        if (data.id == null) {
            return "changed";
        }
      const itemState = state[data.id]
        if (itemState) {
          if (itemState.changed === true || itemState.saved === false) {
            return "changed";
          }
          if (itemState.deleting) {
            return "deleting";
          }
        }
    }

    addRow(items) {
      const item = {}
        items.push(item)
        return item;
    }

    delete(items, item, service, state) {
        if (item.id) {
            state[item.id] = {deleting: true}
        } else {
            return
        }
        service.delete(item.id).then(result => {
          const index = items.findIndex(it => it.id === result)
            items.splice(index, 1);
        })
    }

    rowContextMenu(event, cm, contextSelected) {
      const deleting = contextSelected?.deleting;
        if (deleting !== true) {
            cm.show(event.originalEvent);
        }
    }

    validate(data) {
        return data.name != null
    }
}
