import LocalStoreService from "@/services/storage/LocalStoreService";

export default class ComponentStorage {

    storage = new LocalStoreService()
    viewName = null

    constructor(name) {
        this.viewName = name
    }

    load(name) {
        try {
            return this.storage.load(
                this.viewName + "-" + name
            )
        } catch (e) {
            console.warn(e)
            this.clear(name)
            return null
        }
    }

    store(name, value) {
        this.storage.store(
            this.viewName + "-" + name,
            value
        )
    }

    clear(name) {
        this.storage.clear(this.viewName + "-" + name)
    }
}
