<template>
  <Dialog
      :visible="showDialog"
      header="Group"
      modal
      @update:visible="hideHandler">
    <div class="flex justify-content-center form-editor">
      <div class="card">
        <form @submit.prevent="handleSubmit()" class="p-fluid">
          <div class="field">
            <div class="p-float-label">
              <InputText id="name" v-model="item.name"/>
              <label for="name">Name*</label>
            </div>
          </div>
          <Button type="submit" label="Save" class="mt-2"/>
        </form>
      </div>
    </div>
  </Dialog>
</template>

<script>
import GroupsService from "@/services/entity/GroupsService";

export default {
  name: "GroupDialog",
  props: {
    showDialog: Boolean,
    editItem: Object
  },
  created() {
    this.service = new GroupsService();
  },
  computed: {
    item() {
      return this.editItem
    }
  },
  methods: {
    handleSubmit() {
      if (this.item.id == null) {
        this.service.create(this.item).then(result => {
          this.item.id = result.id
          //this.item.saved = true
          //this.item.changed = false
          this.hideHandler()
        })
      } else {
        this.service.update(this.item).then(() => {
          //this.item.saved = true
          //this.item.changed = false
          this.hideHandler()
        })
      }
    },
    hideHandler() {
      this.$emit("update:showDialog", false)
    }
  }
}
</script>