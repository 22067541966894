<template>
  <TabMenu :model="items"/>
  <div style="width: 100vw; height: calc(100vh - 89px)">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "AdminPanel",
  data() {
    return {
      items: [
        {
          label: "Users",
          to: "users"
        },
        {
          label: "Roles",
          to: "roles"
        },
        {
          label: "Groups",
          to: "groups"
        },
        {
          label: "Task types",
          to: "types"
        },
      ]
    }
  }
}
</script>
