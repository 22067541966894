<template>
  <Dialog header="Episode"
          modal
          :visible="showDialog"
          @update:visible="hideHandler">
    <div class="flex justify-content-center form-editor">
      <div class="card">
        <form @submit.prevent="handleSubmit()" class="p-fluid">
          <div class="field">
            <div class="p-float-label">
              <InputText id="name" v-model="item.name"/>
              <label for="name">Name*</label>
            </div>
          </div>
          <div class="field">
            <div class="p-float-label">
              <InputText id="title" v-model="item.title"/>
              <label for="title">Title</label>
            </div>
          </div>
          <div class="field">
            <div class="p-float-label">
              <InputText id="dropbox" v-model="item.url"/>
              <label for="dropbox">Dropbox</label>
            </div>
          </div>
          <Button type="submit" label="Save" class="mt-2"/>
        </form>
      </div>
    </div>
  </Dialog>
</template>

<script>
import EpisodesService from "@/services/entity/EpisodesService";

export default {
  name: "EpisodeDialog",
  props: {
    showDialog: Boolean,
    editItem: Object
  },
  created() {
    this.episodeService = new EpisodesService();
  },
  computed: {
    item() {
      return this.editItem
    }
  },
  methods: {
    handleSubmit() {
      if (this.item.id == null) {
        this.episodeService.create(this.item).then(result => {
          this.item.id = result.id
          //this.item.saved = true
          //this.item.changed = false
          this.hideHandler()
        })
      } else {
        this.episodeService.update(this.item).then(() => {
          //this.item.saved = true
          //this.item.changed = false
          this.hideHandler()
        })
      }
    },
    hideHandler() {
      this.$emit("update:showDialog", false)
    }
  }
}
</script>