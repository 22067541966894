<template>
  <Dialog header="Role"
          modal
          :visible="showDialog"
          @update:visible="hideHandler">
    <div class="flex justify-content-center form-editor">
      <div class="card">
        <form @submit.prevent="handleSubmit()" class="p-fluid">
          <div class="field">
            <div class="p-float-label">
              <InputText
                id="name"
                v-model="item.name"/>
              <label for="name">Name*</label>
            </div>
          </div>
          <div class="field">
            <div class="p-float-label">
              <Dropdown
                id="group"
                v-model="item.workerGroup"
                :options="groups"
                option-label="name"/>
              <label for="group">Group*</label>
            </div>
          </div>
          <div class="field">
            <div class="p-float-label">
              <Dropdown
                id="role"
                v-model="item.role"
                :options="roles()"
                option-label="name"
                option-value="value"/>
              <label for="role">System role*</label>
            </div>
          </div>
          <Button type="submit" label="Save" class="mt-2"/>
        </form>
      </div>
    </div>
  </Dialog>
</template>

<script>
import RolesService from "@/services/entity/RolesService";
import GroupsService from "@/services/entity/GroupsService";
import {getRoles} from "@/main.ts";

export default {
  name: "RoleDialog",
  props: {
    showDialog: Boolean,
    editItem: Object
  },
  data() {
    return {
      groups: null
    }
  },
  created() {
    this.service = new RolesService();
    this.groupsService = new GroupsService();
  },
  computed: {
    item() {
      return this.editItem
    }
  },
  watch: {
    showDialog(open) {
      if (open) {
        this.groupsService.list().then(data => {
          this.groups = data
        })
      }
    }
  },
  methods: {
    handleSubmit() {
      if (this.item.id == null) {
        this.service.create(this.item).then(result => {
          this.item.id = result.id
          this.hideHandler()
        })
      } else {
        this.service.update(this.item).then(() => {
          this.hideHandler()
        })
      }
    },
    hideHandler() {
      this.$emit("update:showDialog", false)
    },
    roles() {
      return getRoles()
    }
  }
}
</script>
