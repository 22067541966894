export default class LocalStoreService {

    _storage = window.localStorage

    store(name, item) {
      this._storage.setItem(
        name,
        JSON.stringify(item)
      );
    }

  load(name: string) {
    try {
      const item = this._storage.getItem(name);
      if (item == null) {
        return null
      }
      return JSON.parse(item);
    } catch (e) {
      this.clear(name)
      return null
    }
    }
    clear(name) {
        this._storage.removeItem(name)
    }
}
