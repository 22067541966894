import axios from "axios";
import {noRetry} from "@/main";

export default class NotificationService {
  _url = "/api/notifications/"

  getNotifications() {
    return axios.get(this._url, noRetry)
      .then(result => result.data);
  }

  getNotificationsCount() {
    return axios.get(this._url + "count", noRetry)
      .then(result => result.data);
  }

  sendRead() {
    return axios.put(this._url, null, noRetry)
      .then(result => result.data);
  }
}
