import {createRouter, createWebHistory} from 'vue-router'
import EditorView from "@/views/editors/Editors.vue";
import AdminPanel from "@/views/admin/AdminPanel.vue";
import UsersGrid from "@/views/admin/auth/Users.vue";
import GroupsGrid from "@/views/admin/auth/Groups.vue";
import RolesGrid from "@/views/admin/auth/Roles.vue";
import EpisodesGrid from "@/views/editors/grid/Episodes.vue";
import SequencesGrid from "@/views/editors/grid/Sequences.vue";
import ShotsGrid from "@/views/editors/grid/Shots.vue";
import TaskTypes from "@/views/admin/tasks/TaskTypes.vue";

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/login",
      component: () => import("@/form/LoginForm.vue"),
      name: "login",
    },
    {
      path: "/profile",
      component: () => import("@/views/user/UserProfile.vue"),
      name: "profile",
    },
    {
      path: "/",
      component: () => import("@/views/user/UserTasks.vue"),
      name: "userTasks"
    },
    {
      path: "/assets",
      component: () => import("@/views/supervisor/assets/AssetsShotGrid.vue"),
      name: "assetsShotGrid"
    },
    {
      path: "/characters",
      component: () => import("@/views/supervisor/characters/CharactersGrid.vue"),
      name: "charactersGrid"
    },
    {
      path: "/effects",
      component: () => import("@/views/supervisor/effect/EffectsGrid.vue"),
      name: "effectsGrid"
    },
    {
      path: "/lib",
      component: () => import("@/views/library/AssetLibrary.vue"),
      name: "assetLibrary"
    },
    {
      path: "/",
      component: () => import("@/views/user/UserTasks.vue")
    },
    {
      path: "/admin/tasks",
      component: () => import("@/views/supervisor/tasks/TasksGrid.vue")
    },
    {
      path: "/assemble",
      component: () => import("@/views/supervisor/assemble/AssembleGrid.vue"),
    },
    {
      path: "/production",
      component: () => import("@/views/supervisor/production/ProductionGrid.vue"),
    },
    {
      path: "/notifications",
      component: () => import("@/views/user/Notifications.vue"),
    },
    {
      path: "/editor",
      component: EditorView,
      children: [
        {
          path: "episodes",
          component: EpisodesGrid,
        },
        {
          path: "sequences",
          component: SequencesGrid,
        },
        {
          path: "shots",
          component: ShotsGrid,
        },
      ]
    },
    {
      path: "/admin",
      component: AdminPanel,
      children: [
        {
          path: "users",
          component: UsersGrid,
        },
        {
          path: "roles",
          component: RolesGrid,
        },
        {
          path: "groups",
          component: GroupsGrid,
        },
        {
          path: "types",
          component: TaskTypes,
        },
      ]
    }
  ]
});
