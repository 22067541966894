import axios from "axios";
import {noRetry} from "@/main";

export default class UsersService {
    _url = "/api/users/"

    list() {
        return axios.get(this._url)
            .then(result => result.data);
    }

    create(item) {
        return axios.post(this._url, item, noRetry)
            .then(result => result.data)
    }

    update(item) {
        return axios.put(this._url, item, noRetry)
            .then(result => result.data)
    }

    delete(id) {
        return axios.delete(this._url + id, noRetry)
            .then(result => result.data)
    }
}