<template>
  <Dialog header="User"
          modal
          :visible="showDialog"
          @update:visible="hideHandler">
    <div class="flex justify-content-center form-editor">
      <div class="card">
        <form @submit.prevent="handleSubmit()" class="p-fluid">
          <div class="field">
            <div class="p-float-label">
              <InputText
                  id="name"
                  v-model="item.name"/>
              <label for="name">Name*</label>
            </div>
          </div>
          <div class="field">
            <div class="p-float-label">
              <InputText
                  id="name"
                  v-model="item.email"/>
              <label for="name">Email*</label>
            </div>
          </div>
          <div class="field">
            <div class="p-float-label">
              <MultiSelect
                id="roles"
                v-model="item.workerRoles"
                :options="roles"
                :show-toggle-all="false"
                filter
                option-label="name"/>
              <label for="roles">Roles</label>
            </div>
          </div>
          <div
            class="field-checkbox prop-field">
            <Checkbox
              id="disabled"
              v-model="item.disabled"
              binary/>
            <label for="disabled">Suspended</label>
          </div>
          <Button type="submit" label="Save" class="mt-2"/>
        </form>
      </div>
    </div>
  </Dialog>
</template>

<script>
import UserService from "@/services/entity/UsersService";
import RolesService from "@/services/entity/RolesService";

export default {
  name: "UserDialog",
  props: {
    showDialog: Boolean,
    editItem: Object
  },
  created() {
    this.rolesService = new RolesService();
    this.service = new UserService();
  },
  computed: {
    item() {
      return this.editItem
    }
  },
  data() {
    return {
      roles: null
    }
  },
  watch: {
    showDialog(open) {
      if (open) {
        this.rolesService.list().then(data => {
          this.roles = data
        })
      }
    }
  },
  methods: {
    handleSubmit() {
      if (this.item.id == null) {
        this.service.create(this.item).then(result => {
          this.item.id = result.id
          //this.item.saved = true
          //this.item.changed = false
          this.hideHandler()
        })
      } else {
        this.service.update(this.item).then(() => {
          //this.item.saved = true
          //this.item.changed = false
          this.hideHandler()
        })
      }
    },
    hideHandler() {
      this.$emit("update:showDialog", false)
    }
  }
}
</script>
