
import {Options, Vue} from "vue-class-component";
import UserService from "@/services/entity/UserService";
import {MenuItem} from "primevue/menuitem";
import {Ref} from "vue-property-decorator";
import Menu from "primevue/menu";
import LoginForm from "@/form/LoginForm.vue";
import "reflect-metadata"
import NotificationService from "@/services/entity/NotificationService";
import moment from "moment/moment";

@Options({
  components: {
    LoginForm,
  }
})
export default class App extends Vue {

  service = new UserService();
  notificationService = new NotificationService();
  userInfo = {} as any;
  count = 0
  interval

  blockHeight = 0

  @Ref('userMenu')
  readonly userMenu!: Menu

  @Ref('cont')
  readonly cont!: HTMLDivElement

  contentSize!: number

  get userMenuItems() {
    return [
      {
        to: "/profile",
        label: "My Profile",
        icon: "pi pi-fw pi-user",
      },
      {
        label: "Sign out",
        icon: "pi pi-fw pi-sign-out",
        url: "/logout"
      }
    ]
  }

  get currentUserName() {
    return this.userInfo?.name
  }

  get topMenu(): MenuItem[] {
    let items = [] as MenuItem[]
    items.push({
      to: "/assets",
      label: "Assets",
      icon: "pi pi-table",
    })
    /*
        items.push({
          to: "/characters",
          label: "Characters",
          icon: "pi pi-heart",
        })
    */
    items.push({
      to: "/effects",
      label: "Effects",
      icon: "pi pi-flag",
    })
    items.push({
      to: "/assemble",
      label: "Assemble",
      icon: "pi pi-step-forward-alt",
    })
    /*
        items.push({
          to: "/production",
          label: "Production",
          icon: "pi pi-forward",
        })
    */
    items.push({
      to: "/",
      label: "My Tasks",
      icon: "pi pi-list",
    })
    items.push({
      to: "/notifications",
      label: "Notifications",
      icon: "pi pi-info",
    })
    //if (this.hasRole("ROLE_SUPERVISOR") || this.hasRole("ROLE_ADMIN")) {
    items.push({
      to: "/admin/tasks",
      label: "All Tasks",
      icon: "pi pi-users",
    })
    //}
    items.push({
      to: "/lib",
      label: "Libs",
      icon: "pi pi-database",
    })
    items.push({
      to: "/editor/",
      label: "Editors",
      icon: "pi pi-pencil",
    })
    items.push({
      to: "/admin/",
      label: "Admin Panel",
      icon: "pi pi-th-large",
    })
    return items;
  }

  hasRole(role) {
    return this.userInfo?.workerRoles?.map(it => it.role).includes(role)
  }

  mounted() {
    moment.locale(navigator.languages[0])
    this.service.profile().then(result => {
      this.userInfo = result
    })
    this.interval = setInterval(() => {
      this.getNotificationCount();
    }, 30000);
    this.getNotificationCount();
  }

  unmounted() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  getNotificationCount() {
    this.notificationService.getNotificationsCount().then(result => {
      this.count = result
    })
  }

  showMenu(event) {
    this.userMenu.toggle(event);
  }

  goToNotify() {
    if (this.$route.path === "/notifications") {
      this.$router.go("/notifications")
    } else {
      this.$router.push("/notifications")
    }
  }

  handleResize({height}) {
    this.blockHeight = height
  }
}
