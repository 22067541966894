import {createApp} from "vue"
import App from "./App.vue"

import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import "primevue/resources/primevue.css"
import "primeflex/primeflex.css";
import "primeicons/primeicons.css"
import "primevue/resources/themes/saga-blue/theme.css"
import "vue3-resize/dist/vue3-resize.css"
import "@/styles/main.scss"

import {router} from "@/router";
import Button from "primevue/button";
import Listbox from "primevue/listbox";
import Toolbar from "primevue/toolbar";
import SplitButton from "primevue/splitbutton";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import ToggleButton from "primevue/togglebutton";
import Row from "primevue/row";
import Checkbox from "primevue/checkbox";
import ColumnGroup from "primevue/columngroup";
import SelectButton from "primevue/selectbutton";
import ContextMenu from "primevue/contextmenu";
import Textarea from "primevue/textarea";
import Image from "primevue/image";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tree from "primevue/tree";
import Chips from "primevue/chips";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import DataView from "primevue/dataview";
import Panel from "primevue/panel";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Divider from "primevue/divider";
import Tooltip from "primevue/tooltip";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import AutoComplete from "primevue/autocomplete";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import Password from "primevue/password";
import Calendar from "primevue/calendar";
import {ResizeObserver} from "vue3-resize";
import InputSwitch from "primevue/inputswitch";
import BlockUI from "primevue/blockui";
import Galleria from "primevue/galleria";
import TreeSelect from "primevue/treeselect";
import Chip from "primevue/chip";
import axiosRetry from "axios-retry";
import axios from "axios";
import TabMenu from "primevue/tabmenu";
import ProgressBar from "primevue/progressbar";
import InputNumber from "primevue/inputnumber";
import Slider from "primevue/slider";
import TriStateCheckbox from "primevue/tristatecheckbox";
import Avatar from "primevue/avatar";
import OverlayPanel from "primevue/overlaypanel";
import linkify from "vue-linkify";

axiosRetry(axios, {
  retries: 10,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  }
});

const noRetry = {
  "axios-retry": {
    retries: 0
  }
}

const app = createApp(App)
app.use(router);
app.use(PrimeVue, {
  ripple: true,
  /*
      zIndex: {
          modal: 1100,        //dialog, sidebar
          overlay: 1000,      //dropdown, overlaypanel
          menu: 1000,         //overlay menus
          tooltip: 1100       //tooltip
      }
  */
});
app.use(ToastService);
app.use(ConfirmationService);
app.component("resize-observer", ResizeObserver)
app.component("Dialog", Dialog);
app.component("Menu", Menu);
app.component("Card", Card);
app.component("Button", Button);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("InputText", InputText);
app.component("Listbox", Listbox);
app.component("MultiSelect", MultiSelect);
app.component("SelectButton", SelectButton);
app.component("ToggleButton", ToggleButton);
app.component("Dropdown", Dropdown);
app.component("Toolbar", Toolbar);
app.component("SplitButton", SplitButton);
app.component("Row", Row);
app.component("Checkbox", Checkbox);
app.component("ColumnGroup", ColumnGroup);
app.component("ContextMenu", ContextMenu);
app.component("Textarea", Textarea);
app.component("Image", Image);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Tree", Tree);
app.component("Chips", Chips);
app.component("DataViewLayoutOptions", DataViewLayoutOptions);
app.component("DataView", DataView);
app.component("Panel", Panel);
app.component("Splitter", Splitter);
app.component("SplitterPanel", SplitterPanel);
app.component("Menubar", Menubar);
app.component("Divider", Divider);
app.component("Toast", Toast);
app.component("AutoComplete", AutoComplete);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Badge", Badge);
app.component("Password", Password);
app.component("Calendar", Calendar);
app.component("InputSwitch", InputSwitch);
app.component("BlockUI", BlockUI);
app.component("Galleria", Galleria);
app.component("TreeSelect", TreeSelect);
app.component("Chip", Chip);
app.component("TabMenu", TabMenu);
app.component("ProgressBar", ProgressBar);
app.component("InputNumber", InputNumber);
app.component("TriStateCheckbox", TriStateCheckbox);
app.component("Slider", Slider);
app.component("Avatar", Avatar);
app.component("OverlayPanel", OverlayPanel);
app.directive("tooltip", Tooltip);
app.directive("badge", BadgeDirective);
app.directive("linkified", linkify);
app.mount("#app")

function getStatusName(
  status: string
): string {
  switch (status) {
    case "ON_HOLD":
      return "On Hold"
    case "READY_TO_START":
      return "Ready to start"
    case "PROGRESS":
      return "Progress"
    case "COMPLETED":
      return "Completed"
    case "RETAKE":
      return "Retake"
    case "CLOSED":
      return "Closed"
  }
  return status
}

function getTypeIcon(
  type: string
): string {
  if (type === "BACKGROUND") {
    return "pi pi-fw pi-circle-fill"
  }
  if (type === "CHARACTER") {
    return "pi pi-fw pi-heart-fill"
  }
  if (type === "PROPS") {
    return "pi pi-fw pi-box"
  }
  if (type === "RIG") {
    return "pi pi-fw pi-star-fill"
  }
  if (type === "EFFECT") {
    return "pi pi-fw pi-flag-fill"
  }
  return type
}

function getCommentType(
  type: string
): string {
  if (type === "SHOT_ASSET_TASK" || type === "PROPS") {
    return "Asset selection"
  }
  if (type === "SHOT") {
    return "Shot"
  }
  if (type === "ASSET") {
    return "Asset"
  }
  if (type === "TASK") {
    return "Tasks"
  }
  return type;
}

function getRoles() {
  return [
    {
      name: "Reviewer",
      value: "ROLE_REVIEWER",
    },
    {
      name: "Director",
      value: "ROLE_DIRECTOR",
    },
    {
      name: "Supervisor",
      value: "ROLE_SUPERVISOR",
    },
    {
      name: "Admin",
      value: "ROLE_ADMIN",
    },
  ]
}

function capitalize(
  text: string
): string {
  return text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase();
}

export {
  noRetry,
  getStatusName,
  getTypeIcon,
  getCommentType,
  capitalize,
  getRoles
}
