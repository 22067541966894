<template>
  <TabMenu :model="items"/>
  <div style="width: 100vw; height: calc(100vh - 90px)">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "EditorView",
  data() {
    return {
      items: [
        {
          label: "Shots",
          to: "shots"
        },
        {
          label: "Sequences",
          to: "sequences"
        },
        {
          label: "Episodes",
          to: "episodes"
        },
      ]
    }
  }
}
</script>
