<template>
  <Dialog header="Shot"
          modal
          :visible="showDialog"
          @update:visible="hideHandler">
    <div class="flex justify-content-center form-editor">
      <div class="card">
        <form @submit.prevent="handleSubmit()" class="p-fluid">
          <div class="field">
            <div class="p-float-label">
              <AutoComplete
                  id="sequence"
                  field="name"
                  v-model="item.sequence"
                  :suggestions="suggestions"
                  :dropdown="true"
                  @complete="searchSequence($event)"/>
              <label for="sequence">Sequence*</label>
            </div>
          </div>
          <div class="field">
            <div class="p-float-label">
              <InputText id="name" v-model="item.name"/>
              <label for="name">Name*</label>
            </div>
          </div>
          <div class="field">
            <div class="p-float-label">
              <InputText id="frames" v-model="item.frames"/>
              <label for="frames">Duration</label>
            </div>
          </div>
          <div class="field">
            <div class="p-float-label">
              <InputText id="url" v-model="item.url"/>
              <label for="url">Dropbox</label>
            </div>
          </div>
          <div class="field">
            <div class="p-float-label">
              <InputText id="urlVideo" v-model="item.urlVideo"/>
              <label for="urlVideo">Video</label>
            </div>
          </div>
          <Button type="submit" label="Save" class="mt-2"/>
        </form>
      </div>
    </div>
  </Dialog>
</template>

<script>
import ShotsService from "@/services/entity/ShotsService";

export default {
  name: "ShotDialog",
  props: {
    showDialog: Boolean,
    editItem: Object,
    sequences: Object
  },
  created() {
    this.shotsService = new ShotsService();
  },
  computed: {
    item() {
      return this.editItem
    }
  },
  data(){
    return {
      filter: null,
      suggestions: null,
    }
  },
  methods: {
    handleSubmit() {
      if (this.item.id == null) {
        this.shotsService.create(this.item).then(result => {
          this.item.id = result.id
          this.item.seconds = result.seconds
          //this.item.saved = true
          //this.item.changed = false
          this.hideHandler()
        })
      } else {
        this.shotsService.update(this.item).then(() => {
          //this.item.saved = true
          //this.item.changed = false
          this.hideHandler()
        })
      }
    },
    hideHandler() {
      this.$emit("update:showDialog", false)
    },
    searchSequence(event) {
      this.suggestions = this.sequences.filter(
          it => it.name.toLowerCase().includes(event.query.toLowerCase())
      )
    },
  }
}
</script>