import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-column flex h-full"
}
const _hoisted_2 = { class: "mx-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_resize_observer = _resolveComponent("resize-observer")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Menubar = _resolveComponent("Menubar")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _directive_badge = _resolveDirective("badge")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_resize_observer, { onNotify: _ctx.handleResize }, null, 8, ["onNotify"]),
    _createVNode(_component_Toast),
    _createVNode(_component_ConfirmDialog, { group: "confirm" }),
    _createVNode(_component_ConfirmDialog, { group: "comments" }),
    (this.$router.currentRoute.value.name !== 'login')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_Menubar, {
              exact: false,
              model: _ctx.topMenu
            }, {
              end: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.currentUserName), 1),
                _withDirectives(_createVNode(_component_Avatar, {
                  class: "mr-2 cursor-pointer",
                  icon: "pi pi-user",
                  size: "normal",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.count > 0 ? _ctx.goToNotify($event) : _ctx.showMenu($event)))
                }, null, 512), [
                  [
                    _directive_badge,
                    _ctx.count > 0 ? _ctx.count : '',
                    void 0,
                    { small: true }
                  ]
                ])
              ]),
              _: 1
            }, 8, ["model"]),
            _createVNode(_component_Menu, {
              ref: "userMenu",
              model: _ctx.userMenuItems,
              popup: true
            }, null, 8, ["model"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_router_view)
          ])
        ]))
      : (_openBlock(), _createBlock(_component_LoginForm, { key: 1 }))
  ], 64))
}