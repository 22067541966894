import axios from "axios";
import {noRetry} from "@/main";

export default class ShotsService {
  _url = "/api/shots/"

  list() {
    return axios.get(this._url)
      .then(result => result.data);
  }

  create(item) {
    return axios.post(this._url, item, noRetry)
      .then(result => result.data)
  }

  update(item) {
    return axios.put(this._url, item, noRetry)
      .then(result => result.data)
  }

  delete(id) {
    return axios.delete(this._url + id, noRetry)
      .then(result => result.data)
  }

  startAssemble(id) {
    return axios.post(this._url + id + "/assemble", noRetry)
      .then(result => result.data)
  }

  updatePriority(id, value) {
    return axios.post(this._url + id + "/priority", value,
      {headers: {"Content-Type": "text/plain"}, ...noRetry})
      .then(result => result.data)
  }
}
